const manufacturers = {
  manufacturer1: {
    company: "Mobius Bionics",
    logo: "images/mobius-bionics.png",
    elbows: {
      elbow1: {
        category: "Flexion/Extension",
        name: "LUKE arm",
        compatibilityRoutes: [1]
      }
    },
    wrists: {
      wrist1: {
        category: "Rotation",
        name: "LUKE arm",
        compatibilityRoutes: [1]
      },
      wrist2: {
        category: "Flexion/Extension (Powered)",
        name: "LUKE arm",
        compatibilityRoutes: [1]
      }
    },
    hands: {
      hand1: {
        category: "Open/Close",
        name: "LUKE arm",
        compatibilityRoutes: [1]
      }
    }
  },
  manufacturer2: {
    company: "Ottobock.",
    logo: "images/ottobock.png",
    elbows: {
      elbow1: {
        category: "Flexion/Extension",
        name: "DynamicArm",
        tooltip: "Lorem ipsum dolor",
        compatibilityRoutes: [4]
      },
      elbow2: {
        category: "Flexion/Extension",
        name: "DynamicArm Plus",
        compatibilityRoutes: [4]
      },
      elbow3: {
        category: "Locking/Unlocking",
        name: "AxonArm Ergo",
        compatibilityRoutes: [2]
      },
      elbow4: {
        category: "Locking/Unlocking",
        name: "ErgoArm Hybrid plus",
        compatibilityRoutes: [3]
      },
      elbow5: {
        category: "Locking/Unlocking",
        name: "ErgoArm Electronic plus",
        compatibilityRoutes: [3]
      }
    },
    wrists: {
      wrist1: {
        category: "Rotation",
        name: "AxonRotation",
        compatibilityRoutes: [2]
      },
      wrist2: {
        category: "Rotation",
        name: "Electric Wrist Rotator & MyoReotronic",
        compatibilityRoutes: [3]
      },
      wrist3: {
        category: "Rotation",
        name: "Electric Wrist Rotator",
        compatibilityRoutes: [4, 5, 6]
      }
    },
    hands: {
      hand1: {
        category: "Open/Close",
        name: "Michelangelo Hand",
        compatibilityRoutes: [2]
      },
      hand2: {
        category: "Multiple Grasps",
        name: "Michelangelo Hand",
        compatibilityRoutes: [2]
      },
      hand3: {
        category: "Open/Close",
        name: "AxonHook",
        compatibilityRoutes: [2]
      },
      hand4: {
        category: "Open/Close",
        name: "System Electric Hand",
        compatibilityRoutes: [3, 4, 5, 10]
      },
      hand5: {
        category: "Open/Close",
        name: "MyoHand VariPlus Speed",
        compatibilityRoutes: [3, 4, 5, 10]
      },
      hand6: {
        category: "Open/Close",
        name: "System Electric Greifer",
        compatibilityRoutes: [3, 4, 5, 10]
      },
      hand7: {
        category: "Open/Close",
        name: "SensorHand Speed",
        compatibilityRoutes: [3, 4, 5, 10]
      },
      hand7: {
        category: "Open/Close",
        name: "bebionic (any)",
        compatibilityRoutes: [3, 4, 5, 10]
      },
      hand8: {
        category: "Multiple Grasps",
        name: "bebionic (BBH)",
        compatibilityRoutes: [7, 8]
      },
      hand9: {
        category: "Multiple Grasps",
        name: "bebionic (BB1000)",
        compatibilityRoutes: [6]
      }    
    }
  },
  manufacturer3: {
    company: "College Park",
    logo: "images/college-park.png",
    elbows: {
      elbow1: {
        category: "Flexion/Extension",
        name: "Boston Digital Arm",
        compatibilityRoutes: [5, 6]
      },
      elbow2: {
        category: "Flexion/Extension",
        name: "Espire Pro",
        compatibilityRoutes: [5, 6, 7]
      },
      elbow3: {
        category: "Locking/Unlocking",
        name: "Espire Hybrid",
        compatibilityRoutes: [5, 6, 7]
      }
    },
    wrists: {},
    hands: {}
  },
  manufacturer4: {
    company: "Fillauer",
    logo: "images/fillauer.png",
    elbows: {
      elbow1: {
        category: "Flexion/Extension",
        name: "Motion E2 Elbow",
        compatibilityRoutes: [5]
      },
      elbow2: {
        category: "Flexion/Extension",
        name: "Utah Arm 3+",
        compatibilityRoutes: [5, 8]
      },
      elbow3: {
        category: "Locking/Unlocking",
        name: "Utah Hybrid Arm",
        compatibilityRoutes: [5, 8]
      }
    },
    wrists: {
      wrist1: {
        category: "Rotation",
        name: "ProWrist Rotator",
        compatibilityRoutes: [10]
      },
      wrist2: {
        category: "Rotation",
        name: "Standard Wrist Rotator",
        compatibilityRoutes: [5, 6, 9]
      },
      wrist3: {
        category: "Flexion/Extension (powered)",
        name: "Powered Flexion Wrist",
        compatibilityRoutes: [9]
      },
      wrist4: {
        category: "Rotation",
        name: "Standard Wrist Rotator & 6-Band Coaxial Plug",
        compatibilityRoutes: [7, 8, 9]
      }
    },
    hands: {
      hand1: {
        category: "Open/Close",
        name: "ProPlus Hand",
        compatibilityRoutes: [3, 4, 5, 9, 10]
      },
      hand2: {
        category: "Open/Close",
        name: "ProPlus ETD/ETD2",
        compatibilityRoutes: [3, 4, 5, 9, 10]
      }
    }
  },
  manufacturer5: {
    company: "Touch Bionics",
    logo: "images/touch.png",
    elbows: {},
    wrists: {},
    hands: {
      hand1: {
        category: "Open/Close",
        name: "i-Limb Hands",
        compatibilityRoutes: [3, 4, 5, 10]
      },
      hand2: {
        category: "Multiple Grasps",
        name: "i-Limb Quantum",
        compatibilityRoutes: [6]
      }
    }
  },
  manufacturer6: {
    company: "Psyonic",
    logo: "images/psyonic.png",
    elbows: {},
    wrists: {},
    hands: {
      hand1: {
        category: "Open/Close",
        name: "Ability Hand",
        compatibilityRoutes: [3, 4, 5, 10]
      },
      hand2: {
        category: "Multiple Grasps",
        name: "Ability Hand",
        compatibilityRoutes: [7]
      }
    }
  },
  manufacturer7: {
    company: "Taska",
    logo: "images/taska.png",
    elbows: {},
    wrists: {},
    hands: {
      hand1: {
        category: "Open/Close",
        name: "TASKA",
        compatibilityRoutes: [3, 4, 5, 10]
      },
      hand2: {
        category: "Multiple Grasps",
        name: "TASKA",
        compatibilityRoutes: [7, 8 ]
      }
    }
  },
  manufacturer8: {
    company: "Vincent",
    logo: "images/vincent.png",
    elbows: {},
    wrists: {},
    hands: {
      hand1: {
        category: "Open/Close",
        name: "VINCENTevolution3",
        compatibilityRoutes: [3, 4, 5, 10]
      },
      hand2: {
        category: "Multiple Grasps",
        name: "VINCENTevolution3",
        compatibilityRoutes: [6]
      }
    }
  }
};

export default manufacturers;