import React from 'react';
// import Elbows from './Elbows';
// import Wrists from './Wrists';
// import Hands from './Hands';
import Catalog from './Catalog';
import manufacturers from '../equipment';

class App extends React.Component {
  state = {
    manufacturers: {},
    compatibilityRoutes: null,
    // Maybe these don't need to be in an object
    selections: {
      elbow: null,
      wrist: null,
      hand: null
    }
  };

  componentDidMount() {
    this.setState({manufacturers: manufacturers});
  }

  handleComparisons = (key, type, route, status) => {

    // OMG this does what you want BUT!:
    // once a piece gets deselected others should be selected if along the same route

    const compatibilityRoutes = this.state.compatibilityRoutes;
    // See if selecting or deselecting
    // const selections = this.state.selections;
    // console.log(selections.elbow);
    if(status) {
      // if(selections.elbow === null && selections.wrist === null && selections.hand === null) {
      //   console.log('WHOA');
      //   this.setState({ compatibilityRoutes: null });
      // }
      const selections = {...this.state.selections};
      delete selections[type];
      // console.log(selections);
      const remainingSelections = Object.keys(selections);
      // console.log(remainingSelections[0]);
      if(selections[remainingSelections[0]] === null && selections[remainingSelections[1]] === null) {
        this.setState({ compatibilityRoutes: null });
      }
      // Need to revert compatibility routes
      else {
        // console.log(selections[remainingSelections[0]].compatibilityRoutes);
        // console.log(selections[remainingSelections[1]]);
        if(selections[remainingSelections[0]] != null && selections[remainingSelections[1]] != null) {
          const combinedRoutes = [].concat(selections[remainingSelections[0]].compatibilityRoutes, selections[remainingSelections[1]].compatibilityRoutes);
          // this.setState({ compatibilityRoutes: combinedRoutes });
          if(!this.compareRoutes(compatibilityRoutes, combinedRoutes)) {
            this.setState({ compatibilityRoutes: combinedRoutes });
          }
        }
        else if(selections[remainingSelections[0]] != null) {
          if(!this.compareRoutes(compatibilityRoutes, selections[remainingSelections[0]].compatibilityRoutes)) {
            this.setState({ compatibilityRoutes: selections[remainingSelections[0]].compatibilityRoutes });
          }
        }
        else {
          if(!this.compareRoutes(compatibilityRoutes, selections[remainingSelections[1]].compatibilityRoutes)) {
            this.setState({ compatibilityRoutes: selections[remainingSelections[1]].compatibilityRoutes });
          }
        }
      }
    }
    else {
      if(compatibilityRoutes != null) {
        console.log(this.compareRoutes(compatibilityRoutes, route));
        if(!this.compareRoutes(compatibilityRoutes, route)) {
          this.setState({ compatibilityRoutes: route });
        }
      }
      // If route is NOT in compatibility routes:
      else {
        this.setState({ compatibilityRoutes: route });
      }
      // this.setState({ compatibilityRoutes: route });
      // Otherwise do nothing
    }
  };

  compareRoutes = (route1, route2) => {
    for(let i = 0; i < route1.length; i++) {
      for(let j = 0; j < route2.length; j++) {
        if(route1[i] === route2[j]) {
          return true; 
          // if(this.props.selections[this.props.type] === null) {
          //   return true;
          // }
          // else {
          //   if(this.props.selections[this.props.type].company === this.props.manufacturer && this.props.selections[this.props.type].index === this.props.index) {
          //     return true;
          //   }
          // }
        }
      }
    }
    return false;
  }

  updateSelections = (key, type, manufacturer, status, route) => {
    // console.log(key, type, manufacturer);
    // console.log(status);
    const selections = {...this.state.selections};
    if(status) {
      selections[type] = null;
    }
    else {
      selections[type] = {company: manufacturer, index: key, compatibilityRoutes: route};
    }
    // const selections = {...this.state.selections};
    // selections[type] = {company: manufacturer, index: key};
    this.setState({ selections: selections });
  };

  render() {
    return (
      <div className="hardware">
        {Object.keys(this.state.manufacturers).map(key => ( 
          <Catalog 
            key={key}
            details={this.state.manufacturers[key]} 
            handleComparisons={this.handleComparisons}
            updateSelections={this.updateSelections}
            activeRoutes ={this.state.compatibilityRoutes}
            selections={this.state.selections}
          />
        ))}
      </div>
    );
  }
}

export default App;