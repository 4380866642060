import React from 'react';

class Piece extends React.Component {
  // This is an experiment to see if local state might work here
  state = {
    selected: false,
    active: true
  }

  ///////////

  handleClick = () => {
    // this.props.handleComparisons(this.props.index, this.props.type, this.props.details.compatibilityRoutes);
    // this.props.updateSelections(this.props.index, this.props.type, this.props.manufacturer);

    // This should be in it's own function
    // const state = {...this.state};
    // const reverse = !this.state.selected;
    // this.setState({ selected: reverse });
    if(this.active()) {
      this.props.updateSelections(this.props.index, this.props.type, this.props.manufacturer, this.state.selected, this.props.details.compatibilityRoutes);
      this.props.handleComparisons(this.props.index, this.props.type, this.props.details.compatibilityRoutes, this.state.selected);

      const state = {...this.state};
      const reverse = !this.state.selected;
      this.setState({ selected: reverse });

      if(this.state.selected) {
        // console.log('this is selected');
      }
      else {
        // console.log('this is not');
      }
    }
  };

  // I do not yet know if selected and active should be together

  // Selection comparison needs data from the outside
  // selected = () => {
  //   if (this.state.selected) {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // };

  // I don't yet understand why this can't be state

  // Needs some logic in here for routes that are still there
  // Must simplify (and hopefully figure out state!!!!)
  active = () => {
    const activeRoutes = this.props.activeRoutes;
    const equipmentRoutes = this.props.details.compatibilityRoutes;
    if(activeRoutes === null) {
      return true;
    }
    else {
      for(let i = 0; i < activeRoutes.length; i++) {
        for(let j = 0; j < equipmentRoutes.length; j++) {
          if(activeRoutes[i] === equipmentRoutes[j]) {
            // return true; 
            if(this.props.selections[this.props.type] === null) {
              return true;
            }
            else {
              if(this.props.selections[this.props.type].company === this.props.manufacturer && this.props.selections[this.props.type].index === this.props.index) {
                return true;
              }
            }
            // if (this.state.selected) {
            //   return true;
            // }
            // else {
            //   return false;
            // }
          }
        }
      }
      return false;
    }
  };

  // renderActive = () => {
  //   const activeRoutes = this.props.activeRoutes;
  //   const equipmentRoutes = this.props.details.compatibilityRoutes;
  //   const active;

  //   if(activeRoutes === null) {
  //     return true;
  //   }
  //   else {
  //     for(let i = 0; i < activeRoutes.length; i++) {
  //       for(let j = 0; j < equipmentRoutes.length; j++) {
  //         if(activeRoutes[i] === equipmentRoutes[j]) {
  //           return true; 
  //         }
  //       }
  //     }
  //     return false;
  //   }
  // };

  // renderActive might need to be stronger
  renderSelected = () => {

  };

  render() {
    // ONE ATTEMPT TO CONDITIONALLY LOAD A TOOLTIP
    let toolTip;

    if(this.props.details.tooltip) {
      toolTip = (
        <React.Fragment>
          {/* <a href="#" className="tooltip-trigger">More info</a>*/}
          <button className="tooltip-trigger">More info</button>
          <div className="tooltip">
            <p>Super simple lorem ipsum dolor</p>
          </div>
        </React.Fragment>
      );
    }
    else {
      toolTip = null;
    }

    return (
      <div className={"piece " + (this.active() ? "active " : "deactive ") + (this.state.selected ? "selected" : "")} onClick={ this.handleClick }>
        <div className="piece__content">
          <span>{this.props.details.category}</span>
          <h4>{this.props.details.name}</h4>
          { toolTip }
        </div>
      </div>
    )
  }
}

export default Piece;