import React from 'react';
import Piece from './Piece'; 

class Catalog extends React.Component {
  render() {
    return (
      <div className="catalog">
        <h2>{this.props.details.company}</h2>
        <div className="manufacturer-logo">
          <img src={this.props.details.logo} />
        </div>
        <div className="selection selection--elbows">
          {Object.keys(this.props.details.elbows).map(key => (
            <Piece 
              key={key}
              index={key}
              type="elbow"
              manufacturer={this.props.details.company}
              details={this.props.details.elbows[key]} 
              handleComparisons={this.props.handleComparisons}
              updateSelections={this.props.updateSelections}
              activeRoutes={this.props.activeRoutes}
              selections={this.props.selections}
            />
          ))}
        </div>
        <div className="selection selection--wrists">
          {Object.keys(this.props.details.wrists).map(key => (<Piece 
              key={key}
              index={key}
              type="wrist"
              manufacturer={this.props.details.company}
              details={this.props.details.wrists[key]} 
              handleComparisons={this.props.handleComparisons}
              updateSelections={this.props.updateSelections}
              activeRoutes={this.props.activeRoutes}
              selections={this.props.selections}
            />
          ))}
        </div>
        <div className="selection selection--hands">
          {Object.keys(this.props.details.hands).map(key => (<Piece 
              key={key}
              index={key}
              type="hand"
              manufacturer={this.props.details.company}
              details={this.props.details.hands[key]} 
              handleComparisons={this.props.handleComparisons}
              updateSelections={this.props.updateSelections}
              activeRoutes={this.props.activeRoutes}
              selections={this.props.selections}
            />
          ))}
        </div>
      </div>
    )
  }
}

export default Catalog;